import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
        <div className="login-page mx-auto mb-5 pb-5" style={{background: '#f9f9ff',width:'98%'}}> 
        <div className='row justify-content-center'>
  
          <div className='col-md-11 col-11 text-start p-3 mt-5 mb-3'>
            <h2 className='text-center m-2' style={{ fontSize: '30px' }}>A propos de nous !</h2>
  
            <p className='col-12 col-lg-10 mx-auto pb-2 '> Line 63:13:  The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link, use a button and change it with appropriate styles. Learn more: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/HEAD/docs/rules/anchor-is-valid.md  jsx-a11y/anchor-is-valid
     navigable address as the href value. If you cannot prov Line 63:13:  The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link, use a button and change it with appropriate styles. Learn more: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/HEAD/docs/rules/anchor-is-valid.md  jsx-a11y/anchor-is-valid
     navigable address as the href value. If you cannot prov</p>
     <p className='col-12 col-lg-10 mx-auto pb-2 '> Line 63:13:  The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link, use a button and change it with appropriate styles. Learn more: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/HEAD/docs/rules/anchor-is-valid.md  jsx-a11y/anchor-is-valid
     navigable address as the href value. If you cannot prov Line 63:13:  The href attribute requires a valid value to be accessible. Provide a valid, navigable address as the href value. If you cannot provide a valid href, but still need the element to resemble a link, use a button and change it with appropriate styles. Learn more: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/HEAD/docs/rules/anchor-is-valid.md  jsx-a11y/anchor-is-valid
     navigable address as the href value. If you cannot prov</p>
     

            <div className='container '>
    
            </div>
          </div>
        </div>
      </div>
  
    )
  }
}
